<!--
 * @Description: 报价详情
 * @Author: zhang zhen
 * @Date: 2023-07-27 15:33:45
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-10-02 13:28:58
 * @FilePath: /page-sass/src/views/requirement/requirementList/quotePriceInfo.vue
-->
<template>
  <div class="quotePriceInfo">
    <div class="header">
      <div class="header-title">{{ baseInfo.purchaseTitle || '' }}</div>
      <div class="header-subtitle">
        <span class="label">需求编号：{{ baseInfo.purchaseNo || '' }}</span>
        <a class="copy" @click="handleCopy">复制</a>
      </div>
    </div>
    <a-tabs v-model="activeKey">
      <a-tab-pane key="1" tab="报价企业" force-render>
        <div class="info-item">
          <div class="avatar">
            <img :src="coverImage || '/company.png'" alt="" />
          </div>
          <div class="info-view">
            <div class="main-title">
              <span class="label">{{ businessName || '-' }}</span>
              <img src="/passTag.png" alt="" class="tag" />
              <a class="view-info" :href="`/companyInfoPage?id=${businessId}`">查看更多 ></a>
            </div>
            <div class="tags-list">
              <div class="tags-list-item" v-for="row in leftTagList" :key="row">
                <span>
                  <a-tooltip slot="suffix" :title="row">
                    {{ row }}
                  </a-tooltip>
                </span>
              </div>
            </div>
            <div class="info-box" :class="!showMore && 'hidden'" id="contentBoxText">
              {{ description }}
            </div>
            <a-button ghost type="primary" @click="handleEmitChat" class="action-part">
              <div class="btn-inner">
                <img src="/message-3-line.png" alt="" class="icon" />
                <span class="text">咨询TA</span>
              </div>
            </a-button>
          </div>
          <template v-if="needAction">
            <div class="option-btn" v-if="!showMore" @click="showMore = !showMore">
              <span>展开</span>
              <img src="/pic_down.png" alt="" class="icon" />
            </div>
            <div class="option-btn" v-else @click="showMore = !showMore">
              <span>收起</span>
              <img src="/pic_up.png" alt="" class="icon" />
            </div>
          </template>
        </div>
        <h2 class="RequirementConfirmation-title"><span class="title">报价信息</span></h2>
        <template v-for="(item, index) in quoteList">
          <splitLine :label="`${item.quoteDesc}`" iconPath="/quotePrice.png" />
          <QuotePriceTable
            :class="index != 0 && 'less-info'"
            :dataSource="item.quoteHistoryItemVos"
            :total="item.allTotal"
          />
        </template>
      </a-tab-pane>
      <a-tab-pane key="2" tab="需求详情" force-render>
        <RequirementInfo
          :needSteps="false"
          :showOrderInfo="false"
          :purchaseId="purchaseId"
          :needLoadNow="true"
          @showInfo="handleCallbackData"
        />
      </a-tab-pane>
    </a-tabs>

    <div class="action-button-area">
      <a-button @click="handleBack">返回</a-button>
      <a-button type="primary" @click="showInfo = true" v-if="isDetail == '2'">确定合作</a-button>
    </div>

    <a-modal
      title="温馨提示"
      :width="600"
      :visible="showInfo"
      okText="确认"
      centered
      @ok="chooseHim"
      @cancel="showInfo = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>点击“确认”即表示确认合作关系，合作关系成功建立后，请在“订单管理”操作合作流程。</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { postAction, getAction, postQueryAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import RequirementInfo from '@/components/plugins/requirementInfo.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import QuotePriceTable from '@/components/plugins/quotePriceTable.vue'
import ChatMixin from '@/mixins/chatMixins'

export default {
  name: 'quotePriceDetails',
  mixins: [ChatMixin],
  components: {
    RequirementInfo,
    splitLine,
    QuotePriceTable,
  },
  data() {
    return {
      showMore: false,
      activeKey: '1',
      showInfo: false,
      showStep: true,
      purchaseId: null,
      baseInfo: {},
      purchaseItem: [],
      formInfo: {},
      isDetail: '2',
      layoutInfo: {
        wrapperCol: { span: 12 },
        labelCol: { span: 12 },
      },
      form: {}, // 其他信息字段
      formModel: 2,
      quoteId: null,
      totalAmount: 0,
      quoteFileList: [],
      fileList: [],
      showFile: true,
      quoteList: [],
      businessId: null,
      leftTagList: [],
      businessName: null,
      coverImage: null,
      description: null,
      needAction: false,
    }
  },
  created() {
    const { purchaseId, quoteId, isDetail, businessId } = this.$route.query
    isDetail && (this.isDetail = isDetail)
    if (purchaseId) {
      this.purchaseId = purchaseId
      this.quoteId = quoteId
      this.businessId = businessId
      this.handleGetInfo()
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleEmitChat() {
      this.handleChat({
        businessName: this.businessName,
        userId: this.businessId,
      })
    },
    handleBack() {
      this.$router.go(-1)
    },
    checkTextLength() {
      const contentBox = document.getElementById('contentBoxText')
      // 假设两行的高度大约为 line-height * 2，但这里可能需要根据你的具体样式进行调整
      const estimatedTwoLinesHeight = 44 // 这个值需要根据你的字体大小和行高来设置
      console.log(contentBox.clientWidth)
      // 创建一个与contentBox相同样式的临时元素，但不包含-webkit-line-clamp
      const tempElement = document.createElement('div')
      tempElement.style.cssText =
        'width: ' +
        contentBox.clientWidth +
        'px' +
        '; line-height: ' +
        22.4 +
        'px' +
        '; font-size: ' +
        window.getComputedStyle(contentBox).fontSize +
        '; position: absolute; visibility: hidden;display: inline-block;'
      tempElement.textContent = contentBox.textContent
      document.body.appendChild(tempElement)

      // 获取临时元素的高度（这里假设内容不会换行，因此使用scrollWidth作为高度的一个粗略估计）
      // 注意：这种方法在内容可能换行时不准确，但在这个案例中，我们假设内容不会超过容器的宽度
      const actualHeight = tempElement.scrollHeight

      document.body.removeChild(tempElement)

      // 根据实际高度和预估的两行高度来决定是否显示“查看更多”
      console.log(actualHeight)
      if (actualHeight > estimatedTwoLinesHeight) {
        this.needAction = true
      } else {
        this.needAction = false
      }
    },
    handleCallbackBusinessData() {
      getAction('/v2/business/supplier/info/queryByBusinessId', {
        businessId: this.businessId
      }).then((res) => {
        const { success, data } = res
        if (success) {
          const {
            // yearTurnoverDictName,
            minOrderCountDictName,
            sampleTestDictName,
            authMethodDictName,
            printingMethodDictName,
            productPackageDictName,
            purchasingMethodDictName,
          } = data
          this.businessName = data?.businessName
          this.description = data?.description
          this.coverImage = data?.companyLogo
          this.$nextTick(() => {
            const that = this
            // 在页面加载完成后调用该函数
            document.addEventListener('DOMContentLoaded', that.checkTextLength())
          })
          let sampleTestDictNameList = [],
            authMethodDictNameList = [],
            printingMethodDictNameList = []
          if (sampleTestDictName) {
            sampleTestDictNameList = sampleTestDictName.split(',').map((item) => `支持${item}`)
          }
          if (authMethodDictName) {
            authMethodDictNameList = authMethodDictName.split(',').map((item) => `支持${item}`)
          }
          if (printingMethodDictName) {
            printingMethodDictNameList = printingMethodDictName.split(',').map((item) => `支持${item}`)
          }
          let tagList = [
            '最小下单量：' + minOrderCountDictName,
            ...sampleTestDictNameList,
            ...authMethodDictNameList,
            ...printingMethodDictNameList,
            productPackageDictName,
            purchasingMethodDictName,
          ].filter((item) => !!item)
          this.leftTagList = tagList.slice(0, 5)
        }
      })
    },
    handleGetInfo() {
      // 加载报价信息
      postAction('/quote/history', {
        businessId: this.businessId,
        purchaseId: this.purchaseId,
        status: 0,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this.quoteList = data || []
        }
      })
      this.handleCallbackBusinessData();
    },
    /* 复制单号 */
    handleCopy() {
      const _input = document.createElement('input') // 直接构建input
      _input.value = this.baseInfo.purchaseNo // 设置内容
      document.body.appendChild(_input) // 添加临时实例
      _input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(_input) // 删除临时实例
      this.$message.success('复制成功')
    },
    handleCallbackData(e) {
      this.baseInfo = { ...e }
    },
    chooseHim() {
      this.showInfo = false
      // 接口
      postAction('/quote/confirm', {
        purchaseId: this.purchaseId,
        quoteId: this.quoteId,
      }).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.$router.replace('/requirement/requirementList')
        } else {
          this.$message.warning(message)
        }
      })
    },
    // 取消
    handleCancel() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="less" scoped>
.quotePriceInfo {
  padding: 24px 24px 40px;
  box-sizing: border-box;
  background: #fff;
  ::v-deep .ant-tabs-tab {
    padding: 12px 0 20px;
    font-size: 16px;
  }
  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }
  .RequirementConfirmation {
    &-title {
      font-size: 15px;
      color: #605f5f;
      margin-bottom: 24px;
      height: 38px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #efefef;
      margin-top: 25px;

      &:before {
        background: #FF6026;
        display: inline-block;
        content: '';
        width: 5px;
        height: 16px;
        margin-right: 8px;
      }

      .title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000;
        margin-right: 10px;
      }
    }
  }

  .action-button-area {
    margin-top: 60px;
    text-align: center;

    .ant-btn {
      width: 92px;
      height: 38px;
      line-height: 36px;
      font-weight: 600;
      color: #595959;
      & + .ant-btn {
        margin-left: 8px;
      }
    }

    .ant-btn-primary {
      width: 111px;
      color: #fff;
    }
  }
}
.info-card {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

::v-deep .less-info {
  .ant-table {
    color: rgba(0, 0, 0, 0.35) !important;
  }
  .total {
    color: rgba(255, 96, 38, 0.65) !important;
  }
}

.info-item {
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 25px;
  box-sizing: border-box;
  // margin-bottom: 24px;
  background-color: #f7f8fa;
  position: relative;
  .option-btn {
    display: flex;
    align-items: center;
    color: #ff6026;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    cursor: pointer;
    position: absolute;
    right: 29px;
    bottom: 15px;
    .icon {
      width: 12px;
      margin-left: 4px;
    }
  }
  &-cover {
    // margin-top: 10px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
  }

  &:last-of-type {
    border-color: transparent;
  }

  .avatar {
    width: 124px;
    height: 124px;
    background-color: #d9d9d9;
    border: 2px solid #fff;
    border-radius: 6px;
    margin-right: 15px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // border-radius: 4px;
    }
  }

  .info-view {
    display: flex;
    flex-direction: column;
    flex: 1.2 0 0;
    min-width: 0;
    // margin-right: 15%;
    text-align: left;
    // padding-top: 10px;

    .main-title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 23px;
      font-weight: 500;
      line-height: 30px;
      display: flex;
      align-items: center;

      img.tag {
        height: 24px;
        margin-left: 10px;
      }
    }

    .tags {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #888888;
    }
  }
  .info-box {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #595959;
    text-indent: 4px;
    position: relative;
    &.hidden {
      .text-ellipsis-multi(2);
    }
  }
}

.tags-list {
  display: flex;
  align-items: center;
  grid-gap: 8px 8px;
  flex-wrap: wrap;
  height: 26px;
  margin-top: 8px;
  margin-bottom: 10px;
  &-item {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    color: #666;
    line-height: 22px;
    text-align: center;
    padding: 0 5px;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}

.view-info {
  font-size: 15px;
  color: #FF6026;
  font-weight: 500;
  margin-left: 25px;
  // position: absolute;
  // left: 429px;
  // top: 37px;
}

.action-part {
  width: 103px;
  height: 38px;
  font-weight: 500;
  position: absolute;
  right: 29px;
  top: 47px;
  .btn-inner {
    display: flex;
    align-items: center;

    .icon {
      width: 18px;
      margin-right: 8px;
    }

    span.text {
      color: #ff6026;
      font-size: 14px;
    }
  }
}
</style>
